.Container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #fff;
    box-shadow: 8px 8px 20px rgba(0, 0, 0, 0.1);
    border: 1px solid lightgray;
    margin: 20px;
    border-radius: 20px;
    padding: 30px;
}

.mainConatinerWhatwedo {
    display: flex;
    flex-direction: row;
    height: auto;
    /* flex-wrap: wrap; */
    /* margin: 40px; */
    overflow: hidden;
    /* background-color: red; */
}

.ContainerWhatweDo {
    background-color: #f7d3de;
    display: flex;
    height: auto;
    margin: auto;
    max-width: 1200px;
    /* flex-wrap: wrap; */
    width: 90%;
    padding: 20px;
    padding-bottom: 15px;
    border-radius: 10px;
    /* min-width: 250px; */
    /* border: 1px solid lightgray; */
    /* width: 450px; */
    justify-content: center;
    text-align: left;
    margin: 40px;
    box-shadow: 3px 4px 10px rgba(0, 0, 0, 0.1);

}

.ContainerWhatweDo:hover {
    background: linear-gradient(to bottom, #b1008b6e, #ffae0b8a);
    display: flex;
    height: auto;
    margin: auto;
    width: 90%;
    padding: 20px;
    padding-bottom: 15px;
    border-radius: 10px;
    /* min-width: 250px; */
    /* border: 1px solid lightgray; */
    /* width: 450px; */
    justify-content: center;
    text-align: left;
    margin: 40px;
    box-shadow: 3px 4px 10px rgba(0, 0, 0, 0.1);

}

.TextWhatwedo {
    /* text-align: center; */
    padding: 15px 0px;
    font-size: 1.5rem;
    font-weight: 500;
    color: #312951;
    /* font-family: poppins; */
}

.paraWhatweDo {
    font-size: 16px;
    font-weight: 400;
    color: black;
    /* font-family: poppins; */
}

.upperText {
    color: #312951;
    cursor: default;
    font-size: 32px;
    margin-top: 10px;
    text-align: center;
}

/* .crololo {
    width: 100%;
    height: 100%;
}

.UpperSecondText {
    padding-top: 10px 0px;
    font-size: 20px;
    color: #536e83;
    font-weight: 500;
    cursor: default
}

.MainContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 400px;
} */

/* .innerContainer {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    padding: 15px;
    transition: 0.3s;
    border-radius: 20px;
    background-color: #f7d3de;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: auto;
    width: 90%;
    margin: auto;
}

.innerContainer:hover {
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(to bottom, #b1008b7d, #ffae0b8f);
    cursor: default
} */

.ImageNameContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
    gap: 10px;
}

/* .innerContainer:hover .ImageNameContainer {
    display: none;
} */

.innerContainer:hover .paraText {
    color: #fff;
    font-size: 16px;
    font-weight: 400;
}

.Image {
    width: 70px;
    height: 70px;
    /* object-fit: cover; */
    border-radius: 100%;
}

.TextuserName {
    font-size: 14px;
}

.paraContainer {
    text-align: center;
}

.paraText {
    color: black;
    font-weight: 400;
    font-size: 16px;
    padding: 10px;
    text-align: center;
    overflow: hidden;
}

.bottomTextCoontainer {
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    padding-top: 40px;
    padding: 20px;
}

.NameText {
    color: #312951;
    font-size: 1.1rem;
    overflow: hidden;
}

.PositionText {
    color: black;
    font-size: 0.7rem;
    font-weight: 400;
    padding-left: 5px;
    overflow: hidden;
}

/* @media screen and (max-width:1300px) {
    .paraText {
        font-size: 16px;
    }

    .innerContainer:hover .paraText {
        font-size: 16px;
    }

    .innerContainer {
        height: auto;

    }

}

@media screen and (max-width:1130px) {

    .upperText {
        text-align: center;
        font-size: 1.7rem;
    }

    .paraText {
        font-size: 15px;
    }

    .innerContainer:hover .paraText {
        font-size: 15px;
    }
}

@media screen and (max-width:930px) {

    .ImageNameContainer {
        height: 100px;
    }

    .upperText {
        text-align: center;
        font-size: 1.7rem;
    }

    .paraText {
        font-size: 13px;
    }

    .innerContainer:hover .paraText {
        font-size: 13px;
    }
}

@media screen and (max-width:820px) {
    .Container {
        padding: 5px;
    }

    .MainContainer {
        width: 100%;
        height: 400px;
    }

    .paraText {
        font-size: 13px;
        text-align: left;
    }

    .innerContainer:hover .paraText {
        font-size: 13px;
    }
}

@media screen and (max-width:620px) {
    .innerContainer {
        margin: auto;
        width: 90%;
    }

    .upperText {
        text-align: center;
        font-size: 1.7rem;
    }

    .Image {
        width: 50px;
        height: 50px;
        border-radius: 100%;
    }
}

@media screen and (max-width:561px) {
    .innerContainer {
        margin: auto;
        width: 90%;
        max-width: 300px;
    }

    .upperText {
        text-align: center;
        font-size: 1.4rem;
    }

    .ImageNameContainer {
        height: 60px;
    }
}

@media screen and (max-width:480px) {
    .UpperSecondText {
        display: none;
    }

    .MainContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 310px;
    }

    .upperText {
        font-size: 22px;
        padding-top: 10px;
    }

    .ImageNameContainer {
        margin-top: 20px;
    }
}

@media screen and (max-width:370px) {
    .paraText {
        font-size: 12px;
    }

    .innerContainer:hover .paraText {
        font-size: 12px;
    }

    .ImageNameContainer {
        margin-top: 15px;
    }
} */


@media screen and (max-width:1250px) {

    /* .mainConatinerWhatwedo {
        gap: 25px;
        height: auto;
        overflow: hidden;
    } */
    .mainConatinerWhatwedo {
        height: auto;
        display: flex;
        /* flex: 1; */
        /* background-color: red; */
        justify-content: center;
        align-items: center;
        width: 100%;
        gap: 40px;
        flex-wrap: wrap;
        /* overflow: hidden; */
    }

    .ContainerWhatweDo {
        margin: 20px;
        height: auto;
        width: 400px;
    }

    .ContainerWhatweDo:hover {
        margin: 20px;
        height: auto;
        width: 400px;
    }

    .TextWhatwedo {
        padding: 20px 0px;
        font-size: 1.3rem;
    }

    .paraWhatweDo {
        font-size: 16px;
        padding: 0px 20px;
    }
}

@media screen and (max-width:1040px) {

    /* .mainConatinerWhatwedo {
        gap: 25px;
        height: auto;
        overflow: hidden;
    } */
    .mainConatinerWhatwedo {
        height: auto;
        display: flex;
        /* flex: 1; */
        /* background-color: red; */
        justify-content: center;
        align-items: center;
        width: 100%;
        /* gap: 40px; */
        flex-wrap: wrap;
        /* overflow: hidden; */
    }

    .ContainerWhatweDo {
        margin: 20px;
        height: auto;
        width: 300px;
    }

    .ContainerWhatweDo:hover {
        margin: 20px;
        height: auto;
        width: 300px;
    }

    .TextWhatwedo {
        padding: 20px 0px;
        font-size: 1.3rem;
    }

    .paraWhatweDo {
        font-size: 16px;
        padding: 0px 20px;
    }
}

@media screen and (max-width:850px) {

    /* .mainConatinerWhatwedo {
        gap: 25px;
        height: auto;
        overflow: hidden;
    } */
    .mainConatinerWhatwedo {
        height: auto;
        display: flex;
        /* flex: 1; */
        justify-content: center;
        align-items: center;
        width: 100%;
        /* gap: 40px; */
        flex-wrap: wrap;
        /* overflow: hidden; */
    }

    .ContainerWhatweDo {
        margin: 20px;
        height: auto;
        width: 280px;
    }

    .ContainerWhatweDo:hover {
        margin: 20px;
        height: auto;
        width: 280px;
    }

    .TextWhatwedo {
        padding: 20px 0px;
        font-size: 1.3rem;
    }

    .paraWhatweDo {
        font-size: 16px;
        padding: 0px 20px;
    }
}

@media screen and (max-width:630px) {
    .viratoutermostContainer {
        height: 900px;
        padding-top: 30px;
    }

    .ContainerImage {
        height: 140px;
        width: 140px;
        border-radius: 100%;
    }
}

@media screen and (max-width:785px) {
    .mainConatinerWhatwedo {
        height: auto;
        display: flex;
        /* width: auto; */
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
    }

    .ContainerWhatweDo {
        /* margin: 20px; */
        width: 500px;
        height: auto;
        padding-bottom: 20px;
    }

    .ContainerWhatweDo:hover {
        /* margin: 20px; */
        width: 500px;
        height: auto;
        padding-bottom: 20px;
    }

    .TextWhatwedo {
        padding: 10px 0px;
        font-size: 1.3rem;
    }

    .paraWhatweDo {
        font-size: 16px;
        padding: 0px 20px;
    }
}

@media screen and (max-width:685px) {
    .mainConatinerWhatwedo {
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
    }

    .TextWhatwedo {
        padding: 10px 0px;
        font-size: 1.1rem;
    }

    .paraWhatweDo {
        font-size: 16px;
        padding: 0px 20px;
    }

    .ContainerWhatweDo {
        height: auto;
    }

    .ContainerWhatweDo:hover {
        height: auto;
    }

    .upperText {
        font-size: 1.7rem;
    }
}

@media screen and (max-width:600px) {
    .TopTextHeading {
        font-size: 1.5rem;
    }

    .OurCompanyPartnerText {
        font-size: 1.5rem;
    }


    .mainConatinerWhatwedo {
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
    }

    .ContainerWhatweDo {
        height: auto;
        /* max-width: 300px; */
        width: 450px;
        padding: 20px
    }

    .ContainerWhatweDo:hover {
        height: auto;
        /* max-width: 300px; */
        width: 450px;
        padding: 20px
    }

    .paraWhatweDo {
        text-align: left;
    }

    .TextWhatwedo {
        padding: 10px 0px;
        font-size: 1.1rem;
    }

    .paraWhatweDo {
        font-size: 14px;
        padding: 0px 20px;
    }

    .ImageNameContainer {
        margin-top: 8px;
    }
}

@media screen and (max-width:458px) {
    .upperText {
        font-size: 1.5rem;
    }
}

@media screen and (max-width:458px) {

    .TopTextHeading {
        font-size: 1.2rem;
    }

    .OurCompanyPartnerText {
        font-size: 1.2rem;
    }

    .topHeadingInImage {
        font-size: 1.5rem;
        text-align: left;
        padding: 110px 10px;
    }

    .ContainerImage {
        height: 110px;
        width: 110px;
        border-radius: 100%;
    }

    .paraWhatweDo {
        font-size: 14px;
    }

    .ContainerWhatweDo {
        height: auto;
        /* max-width: 300px; */
        width: 400px;
        padding: 12px;
        margin: 8px;
    }

    .ContainerWhatweDo:hover {
        height: auto;
        /* max-width: 300px; */
        width: 400px;
        padding: 12px;
    }

    .mainConatinerWhatwedo {
        margin: 10px;
    }

    .ImageNameContainer {
        margin-top: 4px;
    }
}