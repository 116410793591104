.Container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: auto;
    max-width: 1100px;
    width: 90%;
    /* border: 1px solid lightgray; */
    padding: 80px 40px;
}

.TheMainContainer {
    background-size: cover;
    width: 100%;
    height: auto;
    margin-bottom: 100px;
    margin-top: 70px;


}

.ImageContainer {
    width: 500px;
    /* background-color: red; */
}

.Image {
    width: 100%;
    height: 200px;
    object-fit: contain;
}


.ContainerInputBox {
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 450px;
    height: auto;
    /* border: 1px solid black; */
}

.InputBox {
    padding: 10px 0px;
    padding-left: 15px;
    border: 1px solid gray;
    border-radius: 3px;
}

.Selectbox {
    padding: 10px 0px;
    padding-left: 15px;
    border: 1px solid gray;
    border-radius: 3px;
    color: gray;
    width: auto
}

.ButtonContainer {
    width: 150px;
    text-align: center;
    padding: 7px 0px;
    background-color: #fc0067;
    border-radius: 3px;
    color: white;
    cursor: pointer;
}

.ButtonText {
    font-size: 18px;
    font-weight: 600;
    cursor: pointer;
}

@media screen and (max-width:900px) {
    .Container {
        flex-direction: column;
    }

    /* .Image {
        width: 50%;
    } */
}

@media screen and (max-width:550px) {
    .Container {
        margin-top: 0px;
        margin-bottom: 80px;
        /* border: 1px solid lightgray; */
        background-color: rgba(211, 211, 211, 0.247);
        padding: 50px 20px;
    }

    .ImageContainer {
        /* background-color: red; */
        text-align: center;
    }

    .Image {
        width: 70%;
    }

    .ContainerInputBox {
        width: 350px;
        height: auto;
        /* border: 1px solid black; */
    }
}

@media screen and (max-width:390px) {
    .ImageContainer {
        /* background-color: red; */
        text-align: center;
    }

    .Image {
        width: 50%;
    }

    .ContainerInputBox {
        width: 280px;
        height: auto;
        /* border: 1px solid black; */
    }
}