.CourseViewContainer {
    margin: auto;
    width: 76%;
    margin-bottom: 60px;
    /* background-color: red; */
    /* margin-bottom:70px ; */
}

.innertextContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    /* margin-top: 30px; */
    /* background-color: aqua; */
    margin-bottom: 50px;
}

.InnerContainer {
    border: 1px solid lightgray;
    border-radius: 4px;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
    /* width: 300px; */
    height: auto;
    margin: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0px 30px;
}


@media screen and (max-width : 810px) {
    .MainTopText {
        font-size: 1.7rem;
    }

    .CourseViewContainer {
        width: 86%;
    }

    .innertextContainer {
        margin-bottom: 30px;
    }
}

@media screen and (max-width : 500px) {
    .MainTopText {
        font-size: 1.5rem;
    }
}