.MainContainerForTwoContainer {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    /* background-color: red; */
    /* gap: 20%; */
    /* padding-top: 70px; */
    margin: auto;
    width: 76%;
    margin-bottom: 100px;
}

.BatchContainer {
    display: flex;
    /* justify-content: space-between; */
    gap: 50px;
    /* align-items: center; */
    margin: auto;
    width: 83%;
    /* margin-left: 30px; */
    margin-top: 60px;
    margin-bottom: 60px;
    overflow: visible;
    /* flex-wrap: wrap; */
}

.tableContainer {
    /* margin: auto; */
    margin-top: 40px;
    width: 100%;
}

.topImageeee {
    height: 70vh;
    width: 100%;
    background-color: rgba(0, 0, 255, 0.37);
    margin-top: 100px;
}

.AvailablebatchText {
    margin-left: 10px;
    font-size: 28px;
    font-weight: 600;
    color: #312951;
    overflow: visible;
}

.AvailablebatchTextDiv {
    background-color: white;
    overflow: visible;
    transform: translateY(-20px);
    margin-left: 30px;
    padding-right: 26px;
    padding-left: 20px;

}

.WhatisText {
    text-align: center;
    font-weight: 600;
    font-size: 35px;
    color: #312951;
    margin: auto;
    margin-top: 80px;
}

.CVEditorStyle {
    max-width: 1400px;
    margin: auto;
    width: 95%;
    margin-top: 30px;
    margin-bottom: 60px;
    /* margin: 0px 30px; */
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
    background-color: rgba(235, 234, 234, 0.377);
    display: flex;
    flex-direction: column;
    /* text-align: center; */
    padding: 20px;
    line-height: 25px;
    font-weight: 400;
    font-size: 16px;
    color: gray;
    /* height: auto; */
}

.whatyoulearnText {
    text-align: center;
    padding: 20px 0px;
    /* font-family: poppins; */
    font-size: 20px;
    color: #312951;
    font-weight: 500;
}

.ParatextUnderwhatulearn {
    /* font-family: poppins; */
    font-size: 14px;
    font-weight: 400;
}

.whatYouLearnContainer {
    display: flex;
    flex-direction: row;
}

.whatYouLearninnerTextContainer {
    display: flex;
    /* width: auto; */
    flex-direction: row;
    gap: 10px;
    margin-bottom: 20px;
}

.iconStyle {
    font-size: 25px;
    margin: 2px 0px 0px 0px;
    color: #312951;
}

.whatWeContainer {
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
    width: 40%;
    border-radius: 10px;
    margin: auto;
    /* background-color: #fbe1d8; */
}

.whymeText {
    text-align: center;
    display: flex;
    justify-content: center;
    /* font-family: poppins; */
    padding-top: 10px;
    font-size: 20px;
    color: #312951;
    font-weight: 500;
}

.whyweInnerContainer {
    padding: 20px;
}

.whyweparatext {
    font-size: 16px;
    font-weight: 400;
    color: black;
    /* font-family: poppins; */
}

.AnimateTopText {
    text-align: center;
    display: flex;
    /* align-items: center; */
    align-self: center;
    justify-content: center;
    /* padding: 20px 70px; */
    margin-bottom: 13px;
    color: #312951;
    font-weight: 700;
    cursor: default;
    font-size: 32px;
    margin-top: 80px;
    margin-bottom: 60px;
    /* border: 1px solid #f36ba465; */
}

.FeatureContainer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 80%;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    margin: auto;
    margin-top: 100px;
    margin-bottom: 100px;
    border: 1px solid lightgray;
}

.CKeditorContainerSmall {
    display: none;
}

.FeatureMnainContainer {
    gap: 20px;
    padding: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.roundContainerwithText {
    /* background-color: #fbe1d8; */
    height: 60px;
    width: 60px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);
    justify-content: center;
}

.featureNameDiscContainer {
    display: flex;
    flex-direction: column;
}

.FeatureName {
    /* font-family: poppins; */
    color: #312951;
    font-size: 16px;
    font-weight: 500;
}

.FeatureNameDiscription {
    /* font-family: poppins; */
    font-size: 14px;
}

.iconTextParaContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
    font-size: 22px;
}

.BatchMainContainer {

    display: flex;
    align-items: start;
    max-width: 800px;
    /* margin: auto; */
    width: 100%;
    margin-right: 40px;
    flex-direction: column;
    margin-left: 30px;
    border-radius: 5px;
    overflow: visible;
    padding: 0px 30px;
    border: 2px solid black;

}

.batchInnerContainer {
    display: flex;
    /* min-width: 100px; */
    /* justify-content: space-between; */
    border-bottom: 1px solid black;
    padding: 10px;
    background-color: #ffc1cccc;
}

.RowBackGround {
    background-color: #fef2d0;
}

.PriceContainer {
    display: flex;
    flex-direction: column;
    align-items: start;
    height: auto;
    /* margin: auto; */
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
    border: 1px solid black;
    padding: 20px;
    width: 300px;
    /* background-color: red; */

}

.CourseFeetext {
    font-size: 28px;
    font-weight: 500;
    margin-bottom: 5px;
}

.CourseFee {
    font-size: 33px;
    color: green;
    font-weight: 600;
    margin-bottom: 5px;
    margin-bottom: 3px;
}

.demoButton {
    width: 150px;
    padding: 10px 0px;
    border-radius: 10px;
    background-color: #312951;
    text-align: center;
    font-size: 16px;
    margin-top: 20px;
    margin-bottom: 20px;
    color: white;
}

.paraBottomText {
    font-size: 16px;
    /* text-align: center; */
}

.InnerBatchText {
    /* font-family: poppins sans-serif; */
    font-size: 17px;
    font-weight: 500;
}

.InnerBatchparatext {
    /* font-family: poppins sans-serif; */
    font-size: 14px;
    padding: 2px 0px;
    font-weight: 300;
    color: gray;
    padding-left: 10px;
}

.CKeditorContainer {
    width: 100%;
    margin: auto;
}

.outmainContainer {
    display: flex;
    flex-direction: row;
    margin: auto;
    width: 80%;
}

.miniBatchdiv {
    margin-bottom: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    /* background-color: #312951; */
    /* width: 100%; */
}

.mapContainer {
    width: 45%;
    padding: 25px;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #ffc1cc7a;
    box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.1);
    /* border: 1px solid black; */
    margin: 10px;
    border-radius: 10px;

}

.mapSmallfirstContainer {
    width: 30%;
    border-radius: 10px;
    padding: 10px;
    box-shadow: 4px 6px 20px rgba(0, 0, 0, 0.1);
    /* background-color: #aea2e6c7; */
    background-color: #FFC1CC;
}

.mapContainerinerlongText {
    max-width: 400px;
    text-align: left;
    font-size: 13px;
    font-weight: 300;
    margin-left: 20px;
    background-color: #ffc1cc76;
    /* border: 1px solid black; */
    border-radius: 10px;
    color: gray;
    padding: 14px;
    box-shadow: 4px 6px 20px rgba(0, 0, 0, 0.1);
}

.RatingandrateUSConatiner {
    display: flex;
    margin: auto;
    width: 80%;
}

.RateByUserConatiner {
    height: 450px;
    margin: auto;
    width: 80%;
    max-width: 400px;
    margin-right: 20px;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
    margin-top: 30px;
    padding: 10px;
}

.ImgReview {
    /* height: 150px; */
    width: 180px;
    padding-top: 20px;
    margin: auto;
    object-fit: contain;
}

.buttonReview {
    width: 35%;
    padding: 8px;
    margin-left: 10px;
    background-color: #312951;
    color: white;
    text-align: center;
    border-radius: 10px;
    margin-top: 20px;
}

.TextAreaUnderreview {
    height: 200px;
    width: 350px;
    margin-left: 10px;
    margin-top: 10px;
    padding: 5px;
    border: 1px solid lightgray;
}

.reviewHeading {
    font-size: 30px;
    text-align: left;
    color: orange;
    margin-left: 5px;
}

.animateThemain {
    display: flex;
    justify-content: space-between;
    align-content: center;
    margin: auto;
    width: 72%;

}

.AnimateTopText {
    text-align: center;
    display: flex;
    /* align-items: center; */
    align-self: center;
    justify-content: center;
    /* padding: 20px 70px; */
    margin-bottom: 13px;
    color: #312951;
    font-weight: 700;
    cursor: default;
    font-size: 32px;
    margin-top: 80px;
    margin-bottom: 60px;
    /* border: 1px solid #f36ba465; */
}

.animateTheBottomOne {
    height: 150px;
    width: 230px;
    border-radius: 20px;
    border: 2px solid #fc0069aa;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fed5e3a4;

}

.animateTheBottomOne22 {
    height: 150px;
    width: 230px;
    border-radius: 20px;
    border: 2px solid #fc0069aa;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 30px;
    background-color: #fed5e3a4;

}

.animateTheBottomOne11 {
    height: 150px;
    width: 230px;
    border-radius: 20px;
    border: 2px solid #fc0069aa;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 120px;
    margin-left: 20px;
    margin-right: 30px;
    background-color: #fed5e3a4;

}

.animateTheParaText {
    text-align: center;
    padding: 0px 10px;
    font-weight: 500;
    float: left;
}

.abc {
    /* background-color: red; */
    overflow: hidden;
    height: 100px;
    width: 150px;
    margin-top: 60px;
    margin-left: 20px;
}

.abc2 {
    /* background-color: red; */
    overflow: hidden;
    height: 100px;
    width: 150px;
    margin-top: 120px;
}

.path {
    stroke-dasharray: 1000;
    stroke-dashoffset: 1000;
    animation: dash 3s linear forwards;
    animation-iteration-count: 1;
    animation-delay: 1s;
}

@keyframes dash {
    to {
        stroke-dashoffset: 0;
    }
}

.dashed {
    stroke-dasharray: 8, 12;
}

#arrow {
    animation: arrow 2s linear forwards;
    opacity: 0;
}

@keyframes arrow {
    to {
        opacity: 1;
    }
}

.tableContainerSmall {
    display: none;
}



@media screen and (max-width:1100px) {
    .animateThemain {
        display: flex;
        justify-content: space-between;
        align-content: center;
        margin: auto;
        width: 72%;
        margin-top: 100px;
    }

    .animateTheBottomOne {
        height: 150px;
        width: 300px;

    }

    .animateTheBottomOne22 {
        height: 150px;
        width: 300px;
        margin-left: 20px;

    }

    .animateTheBottomOne11 {
        height: 150px;
        width: 300px;
        margin-top: 100px;
        margin-left: 10px;
        margin-right: 15px;

    }

    .animateTheParaText {
        text-align: center;
        padding: 0px 5px;
        font-weight: 400;
        font-size: 16px !important;
    }

    .abc {
        /* background-color: red; */
        overflow: hidden;
        height: 70px;
        width: 120px;
        margin-top: 60px;
        margin-left: 20px;
    }

    .abc2 {
        /* background-color: red; */
        overflow: hidden;
        height: 70px;
        width: 120px;
        margin-top: 60px;
    }
}

@media screen and (max-width:900px) {
    .RatingandrateUSConatiner {
        flex-direction: column;
    }

    .WhatisText {
        font-size: 30px;
    }

    .RateByUserConatiner {
        margin: auto;
        margin-bottom: 80px;
    }

    .TextBatchTop {
        font-size: 16px !important;
    }

    .BottomBatchdataText {
        font-size: 14px !important;
    }

    .BatchContainer {
        width: 90% !important;
        padding-top: 50px;
        margin: auto;
        margin-bottom: 40px;
    }

    .BatchMainContainer {
        width: 100% !important;
        margin: auto;
    }

    .tableContainer {
        /* margin: auto; */
        margin-top: 0px;
        padding-top: 10px;
        padding-bottom: 30px;
    }
}

@media screen and (max-width : 700px) {
    .tableContainer {
        display: none;
    }

    .tableContainerSmall {
        display: block;
        /* border: 1px solid lightgray; */
        width: 100%;

    }

    .ProperDivSmall {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 20px;
        border-bottom: 1px solid rgba(211, 211, 211, 0.536);
        padding-bottom: 5px;
    }

    .ProperDivSmallName {}

    .animateThemain {
        flex-wrap: wrap;
        width: 95%;
        margin-top: 40px;

    }

    .abc {
        display: none;
    }

    .abc2 {
        display: none;
    }

    .animateTheBottomOne {
        margin: auto;
        margin-bottom: 30px;
        margin-top: 0px;
        width: 80%
    }

    .animateTheBottomOne22 {
        margin-left: 0px;
        background-color: #fed5e3a4;
        margin: auto;
        /* margin-bottom: 30px; */
        width: 80%;
        margin-top: 30px;
    }

    .animateTheBottomOne11 {

        margin: auto;
        width: 80%;
    }

    .AnimateTopText {
        color: #312951;
        font-weight: 600;
        cursor: default;
        font-size: 1.5rem;
        margin-top: 40px;
        margin-bottom: 0px;
        /* border: 1px solid #f36ba465; */
    }


}

@media screen and (max-width:630px) {
    /* .RatingandrateUSConatiner {
        flex-direction: column;
    } */

    .RateByUserConatiner {
        margin: auto;
        width: 95%;
        margin-bottom: 80px;
    }

    .WhatisText {
        font-size: 1.7rem;
    }



    .TextAreaUnderreview {
        height: 180px;
        width: 300px;
    }

    .TextBatchTop {
        font-size: 14px !important;
        font-weight: 500 !important;
    }

    .BottomBatchdataText {
        font-size: 14px !important;
    }

    .BatchContainer {
        width: 100% !important;
        margin: auto;
        margin-bottom: 40px;
    }

    .BatchMainContainer {
        width: 90% !important;
        margin: auto;
    }
}


@media screen and (max-width:500px) {
    .AvailablebatchText {
        font-size: 20px;
    }

    .AvailablebatchTextDiv {
        transform: translateY(-37px);

    }
}

@media screen and (max-width:510px) {
    .TextBatchTop {
        font-size: 12px !important;
        font-weight: 500 !important;
    }

    .BottomBatchdataText {
        font-size: 12px !important;
    }

    .BatchContainer {
        width: 100% !important;
        margin: auto;
        margin-bottom: 40px;
    }

    .BatchMainContainer {
        width: 90% !important;
        margin: auto;
    }


}

@media screen and (max-width:450px) {
    .buttonReview {
        width: 28%;
        padding: 6px;
        margin-left: 10px;
    }

    .TextAreaUnderreview {
        height: 180px;
        width: 250px;
    }

    .WhatisText {
        font-size: 1.5rem;
    }

    .TextBatchTop {
        font-size: 12px !important;
        font-weight: 500 !important;
    }

    .BottomBatchdataText {
        font-size: 9px !important;
    }

    .inneroneDivvv {
        height: 20px;
    }

    .BatchContainer {
        width: 100% !important;
        margin: auto;
        margin-bottom: 40px;
    }

    .BatchMainContainer {
        width: 90% !important;
        margin: auto;
    }
}

@media screen and (max-width:987px) {
    .CVEditorStyle {
        font-size: 18px;
        height: 400px;
    }
}

@media screen and (max-width:1280px) {
    .CourseFeetext {
        font-size: 22px;
        font-weight: 500;
        margin-bottom: 5px;
    }

    .CourseFee {
        font-size: 26px;
        color: green;
        margin-bottom: 4px;
        margin-bottom: 2px;
    }

    .demoButton {
        width: 120px;
        padding: 7px 0px;
        border-radius: 8px;
        font-size: 14px;
        margin-top: 15px;
        margin-bottom: 17px;
    }
}

@media screen and (max-width:1134px) {
    .whymeText {
        font-size: 15px;
    }

    .whyweparatext {
        font-size: 12px;
    }

    .iconTextParaContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 5px;
    }

    .whatWeContainer {
        box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
        width: 30%;
        border-radius: 10px;
        margin-right: 20px;
        /* margin: auto; */
        /* background-color: #fbe1d8; */
    }

    .iconTextParaContainer {
        margin-bottom: 10px;
    }

    .CVEditorStyle {
        height: auto;
    }
}

@media screen and (max-width:850px) {
    .whatWeContainer {
        display: none;
    }

    .BatchContainer {
        flex-direction: column;
        width: 85%;
    }

    .PriceContainer {
        margin: auto;
        margin-top: 30px;
    }

    .ParatextUnderwhatulearn {
        /* font-family: poppins; */
        font-size: 11px;
        font-weight: 400;
    }

    /* .CVEditorStyle {
        width: 70%;
        box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-bottom: 10px;
        font-size: 15px;
        height: auto;
    } */

    .FeatureContainer {
        margin-top: 60px;
        margin-bottom: 60px;
    }

    .roundContainerwithText {
        /* background-color: #fbe1d8; */
        height: 40px;
        width: 40px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);
        justify-content: center;
    }

    .mapContainer {
        width: 90%;
        height: auto;
    }

    .mapContainerinerlongText {
        /* max-width: 400px; */
        width: auto;
        height: auto;
        margin-left: 20px;
        padding: 10px;
        font-size: 12px;
    }

    .mapSmallfirstContainer {
        min-width: 200px;
        width: auto;
        border-radius: 10px;
        padding: 10px;
        box-shadow: 4px 6px 20px rgba(0, 0, 0, 0.1);
    }
}

@media screen and (max-width:930px) {
    .CKeditorContainerSmall {
        display: flex;
        margin: auto;
        width: 84%;
    }
}

@media screen and (max-width:410px) {
    .TextBatchTop {
        font-size: 12px !important;
        font-weight: 500 !important;
    }

    .BottomBatchdataText {
        font-size: 10px !important;
    }

    .BatchContainer {
        width: 100% !important;
        margin: auto;
        margin-bottom: 40px;
    }

    .BatchMainContainer {
        width: 90% !important;
        margin: auto;
    }
}

@media screen and (max-width:535px) {
    .CVEditorStyle {
        width: 100%;
        height: auto;
        /* margin: auto; */
        box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-bottom: 0px;
        font-size: 16px;
    }

    .FeatureContainer {
        margin-top: 30px;
        margin-bottom: 30px;
    }
}

@media screen and (max-width:500px) {
    .ParatextUnderwhatulearn {
        /* font-family: poppins; */
        font-size: 9px;
        font-weight: 400;
    }

    .roundText {
        font-size: 10px;
    }

    .CourseFeetext {
        font-size: 22px;
        font-weight: 500;
        margin-bottom: 5px;
    }

    .CourseFee {
        font-size: 24px;
        color: green;
        font-weight: 600;
        margin-bottom: 5px;
        margin-bottom: 3px;
    }

    .PriceContainer {
        padding: 8px;
        width: 250px;
        /* background-color: red; */

    }

    .outmainContainer {
        display: flex;
        flex-direction: row;
        margin: auto;
        width: 95%;
    }


    .roundContainerwithText {
        /* background-color: #fbe1d8; */
        height: 25px;
        width: 30px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);
        justify-content: center;
    }

    .FeatureName {
        /* font-family: poppins; */
        color: #312951;
        font-size: 14px;
        font-weight: 500;
    }

    .FeatureNameDiscription {
        /* font-family: poppins; */
        font-size: 12px;
    }

    .mapContainer {
        width: 90%;
        height: 26vh;
    }

    .mapContainerinerlongText {
        display: none;
    }

    .mapContainer {
        width: 80%;
        height: 35vh;
        margin: 10px;

    }

    .mapSmallfirstContainer {
        width: 90%;
    }

    .BatchMainContainer {
        margin-top: 20px;
        padding-top: 20px;
        border-radius: 5px;

    }
}

@media screen and (max-width:382px) {
    .CVEditorStyle {
        width: 100%;
        height: auto;
        /* margin: auto; */
        box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-bottom: 1px;
        font-size: 12px;
    }

    .InnerBatchText {
        font-size: 15px;
        font-weight: 500;
    }

    .InnerBatchparatext {
        /* font-family: poppins sans-serif; */
        font-size: 14px;
        font-weight: 300;
        color: gray;
        padding-left: 10px;
    }

    .mapContainer {
        width: 80%;
        height: 35vh;
        margin: 10px;

    }

    .AvailablebatchTextDiv {
        transform: translateY(-25px);

    }

    .BatchMainContainer {
        margin-top: 10px;
        padding-top: 10px;
        border-radius: 5px;

    }

}

@media screen and (max-width:535px) {
    .CKeditorContainer {
        margin-bottom: 60px;
    }
}

/* @media screen and (max-width:500px) {
    .roundText {
        font-size: 8px;
    }

    .roundContainerwithText {
        height: 20px;
        width: 20px;
    }
} */