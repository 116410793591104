.Container {
    display: flex;
    align-items: start;
    flex-direction: column;
    height: auto;
    /* margin-left: 13%; */
    max-width: 750px;
    margin-bottom: 100px;
    margin-Top: 60px;
}

.TopHeading {
    font-size: 28px;
    font-weight: 600;
}

.innerTopTextContainer {
    margin-top: 12px;
}

.UserContainer {
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.parentIconContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 30px;
}

.IconContainer {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
}

.instructorName {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 3px;
    color: #312951;
}

/* .imageContainer {
    height: 200px;
    width: 200px;
    border-radius: 100%;
} */

.image {
    height: 120px;
    width: 120px;
    border-radius: 70%;
    /* object-fit: contain; */
}

.paratext {
    font-size: 16px;
    margin-top: 20px;
}

@media screen and (max-width:930px) {
    .Container {
        display: flex;
        align-items: start;
        flex-direction: column;
        height: auto;
        /* margin-left: 13%; */
        max-width: 100%;
        margin-bottom: 100px;
        margin-Top: 60px;
        /* background-color: blue; */
    }
}

@media screen and (max-width:520px) {
    .image {
        height: 90px;
        width: 90px;
        border-radius: 70%;
        /* object-fit: contain; */
    }

    .Ratingtext {
        font-size: 14px;
    }

}