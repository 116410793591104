.container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 70px);
    gap: 90px;
    background-color: #fff;
    border-bottom: 1px solid lightgray;
    /* border: 1px solid #fc0069a2; */
    margin: 0px 50px;
    /* box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
    border-radius: 5px; */
}

.TopImage {
    height: 450px;
    width: 450px;
    border-radius: 2px;
    box-shadow: 4px 4px 7px rgba(0, 0, 0, 0.1);
}

.TextInputcontainer {
    padding: 40px 40px;
    width: 40%;
}

.tagLine {
    display: flex;
    font-Size: 32px;
    color: #312951;
    font-weight: 700;
    cursor: default
}

.bottomtagline {
    font-size: 15px;
    margin-top: 4px;
    font-weight: 300;
    font-size: "1rem";
    color: gray;
    font-weight: 400;
}

.textInput {
    width: 100%;
    height: 40px;
    border-radius: 10px;
    padding-left: 20px;
    cursor: pointer;
    margin-top: 20px;
}

.verficationText {
    font-size: 12px;
    margin-top: 6px;
    color: #536e83;
    cursor: default
}

.buttonDiv {
    display: flex;
    justify-content: center;
    padding: 10px 15px;
    width: 200px;
    font-size: 16px;
    /* margin:auto; */
    border-radius: 10px;
    margin-top: 20px;
    background-color: #312951;
    color: white;
    cursor: pointer
        /* border */
}

/* @media screen and (max-width:900px) {
    .cal{

    }
    
} */
@media screen and (max-width:1350px) {
    .container {
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        gap: 30px;
    }
}

@media screen and (max-width:1168px) {
    .container {
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        gap: 30px;
    }

    .TextInputcontainer {
        padding: 40px 40px;
        width: 80%;
    }

    .textInput {
        height: 50px;
    }

    .TopImage {
        display: none;
    }

    .tagLine {
        font-size: 1.7rem;
    }

    .buttonDiv {
        font-size: 20px;
        /* border */
    }
}

@media screen and (max-width:850px) {
    .TextInputcontainer {
        padding: 40px 0px;
        width: 98%;
    }
}

@media screen and (max-width:658px) {
    .container {
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        margin: 0px;
        width: 100%;
        /* align-items: normal; */
    }

    .TextInputcontainer {
        padding: 40px 0px;
        margin: auto;
        width: 80%;
    }

    .TopImage {
        display: none;
    }

    .tagLine {
        font-size: 1.7rem;
    }
}

@media screen and (max-width:450px) {
    .container {
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        /* margin: 20px; */
        /* margin-top: 0px; */
    }

    .TopImage {
        display: none;
    }

    .tagLine {
        font-size: 1.5rem;
    }

    .buttonDiv {
        padding: 10px 14px;
        width: 170px;
        margin-top: 20px;
        font-size: 18px;
        /* border */
    }
}

@media screen and (max-width:376px) {
    .container {
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
    }

    .TopImage {
        display: none;
    }

    .textInput {
        width: 100%;
        height: 30px;
        border-radius: 10px;
        padding-left: 10px;
        margin-top: 20px;
        font-size: 10px;
    }

    .buttonDiv {
        padding: 8px 10px;
        width: 160px;
        border-radius: 10px;
        margin-top: 20px;
        background-color: #312951;
        color: white;
        font-size: 15px;
        /* border */
    }

    .tagLine {
        font-size: 1.4rem;
    }

    .verficationText {
        font-size: 10px;
    }
}