/* @media screen and (max-width : 800px) {
    footer>div {
        flex-direction: row !important;
    }

    footer>div>div {
        width: 95% !important;
        margin: auto !important;
        flex-direction: column !important;
    }

    footer>div>div img {
        margin-left: -8% !important;
    }

    footer>div>div:nth-child(1) {
        align-items: flex-start !important;
    }
} */

.MainContainer {
    display: flex;
    gap: 20px;
    justify-content: space-evenly;
    padding: 50px 10px;
    flex-wrap: wrap;
}

.ImageDiv {
    height: 80px;
    overflow: hidden;
}

.firstDiv11 {
    display: flex;
    gap: 0px;
    flex-direction: column;
    align-items: center;
    align-self: center;
}

.SocialIcon {
    font-size: 30px;
    color: black;
}

.SecondThriedDiv {
    display: flex;
    gap: 0px;
    flex-direction: column;
    align-items: flex-start;
}

.SecondThriedDivXX {
    display: flex;
    gap: 0px;
    width: 15%;
    flex-direction: column;
    align-items: flex-start;
}

.SecondThriedDivTopText {
    font-size: 1.1rem;
    font-weight: 600;
    margin-bottom: 10px;
    color: #29347b;
}

.SecondThriedDivTopTextXX {
    font-size: 1.1rem;
    font-weight: 600;
    margin-bottom: 10px;
    color: #29347b;
}

.linkText {
    color: black;
    font-size: 16px;
    margin-bottom: 8px;
    text-decoration: none;
    cursor: pointer;
}

.BottomsmallFooter {
    height: 50px;
    background: #fb489360;
    display: flex;
    align-items: center;
    justify-content: center;
}

.BottomBlackText {
    font-size: 16px;
}

.firstDiv22 {
    display: none;
}



@media screen and (max-width : 800px) {
    .linkText {
        font-size: 1rem;
        margin-bottom: 10px;

    }

    .SecondThriedDivTopText {
        font-size: 1.3rem;
        font-weight: 400;
        margin-bottom: 15px;
    }

    .SecondThriedDivTopTextXX {
        font-size: 1.3rem;
        font-weight: 400;
        margin-bottom: 15px;
    }

    .SocialIcon {
        font-size: 20px;
    }

    .ImageDiv {
        height: 60px;
    }
}

@media screen and (max-width : 600px) {
    .linkText {
        font-size: 14px;
        margin-bottom: 10px;

    }

    .SecondThriedDivTopText {
        font-size: 16px;
        font-weight: 400;
        margin-bottom: 15px;
    }

    .SecondThriedDivTopTextXX {
        font-size: 16px;
        font-weight: 400;
        text-align: center;
        margin-bottom: 15px;
    }

    .SocialIcon {
        font-size: 15px;
    }

    .ImageDiv {
        height: 30px;
    }

    .SecondThriedDivXX {
        display: flex;
        gap: 0px;
        width: 60%;
        flex-direction: column;
        align-items: flex-start;
    }
}


@media screen and (max-width : 530px) {
    .linkText {
        font-size: 12px;
        margin-bottom: 10px;

    }

    .SecondThriedDivTopText {
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 10px;
    }

    .SecondThriedDivTopTextXX {
        font-size: 14px;
        font-weight: 400;
        margin: auto;
        margin-bottom: 10px;

    }

    .SocialIcon {
        font-size: 15px;
        margin-bottom: 40px;
    }



    .BottomBlackText {
        font-size: 16px !important;
    }

    .ancorText {
        font-size: 16px !important;
    }

    .firstDiv11 {
        display: none;
    }

    .firstDiv22 {
        display: block;
    }

    .ImageDiv {
        height: 60px;
        width: 60px;

        margin: auto;
        margin-top: 40px;
    }
}

@media screen and (max-width : 420px) {
    .linkText {
        font-size: 10px;
        margin-bottom: 10px;

    }

    .SecondThriedDivTopText {
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 10px;
    }

    .SocialIcon {
        font-size: 12px;
        margin-bottom: 40px;
    }

    .ImageDiv {
        height: 50px;
        width: 50px;
        margin: auto;
        margin-top: 40px;
    }

    .BottomBlackText {
        font-size: 14px !important;
    }

    .ancorText {
        font-size: 14px !important;
    }
}