.container {
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    height: 80vh;
    /* padding-top: 130px; */
    /* gap: 50px; */
    /* height: auto; */
    max-width: 1550px;
    /* padding-left: 30px; */
    padding: 0px 200px;
    /* padding-bottom: 70px; */
    margin: auto;
    width: 100%;
    background-color: #FED5E3;
    /* border-radius: 20px; */
    /* margin-top: 70px; */
    margin-bottom: 90px;
    overflow: visible;
    /* padding-top: 80px; */
    /* border-radius: 20px; */
}

.imageContainer {
    display: flex;
    justify-content: end;
    overflow: visible;
    padding-left: 120px;
}

.animateThemain {
    display: flex;
    justify-content: space-between;
    align-content: center;
    margin: auto;
    width: 72%;

}

.AnimateTopText {
    text-align: center;
    display: flex;
    /* align-items: center; */
    align-self: center;
    justify-content: center;
    /* padding: 20px 70px; */
    margin-bottom: 13px;
    color: #312951;
    font-weight: 700;
    cursor: default;
    font-size: 32px;
    margin-top: 80px;
    padding-bottom: 20px;
    margin-bottom: 60px;
    /* border: 1px solid #f36ba465; */
}

.animateTheBottomOne {
    height: 150px;
    width: 230px;
    border-radius: 20px;
    border: 2px solid #fc0069aa;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fed5e3a4;

}

.animateTheBottomOne22 {
    height: 150px;
    width: 230px;
    border-radius: 20px;
    border: 2px solid #fc0069aa;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 30px;
    background-color: #fed5e3a4;

}

.animateTheBottomOne11 {
    height: 150px;
    width: 230px;
    border-radius: 20px;
    border: 2px solid #fc0069aa;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 120px;
    margin-left: 20px;
    margin-right: 30px;
    background-color: #fed5e3a4;

}

.animateTheParaText {
    text-align: center;
    padding: 0px 10px;
    font-weight: 500;
    float: left;
}

.abc {
    /* background-color: red; */
    overflow: hidden;
    height: 100px;
    width: 150px;
    margin-top: 60px;
    margin-left: 20px;
}

.abc2 {
    /* background-color: red; */
    overflow: hidden;
    height: 100px;
    width: 150px;
    margin-top: 120px;
}

.path {
    stroke-dasharray: 1000;
    stroke-dashoffset: 1000;
    animation: dash 3s linear forwards;
    animation-iteration-count: 1;
    animation-delay: 1s;
}

@keyframes dash {
    to {
        stroke-dashoffset: 0;
    }
}

.dashed {
    stroke-dasharray: 8, 12;
}

#arrow {
    animation: arrow 2s linear forwards;
    opacity: 0;
}

@keyframes arrow {
    to {
        opacity: 1;
    }
}

.TopImage {
    /* height: 700px; */
    width: 550px;
    object-fit: contain;
    overflow: visible;
    color: blue;
    /* max-width: 85%; */
}

.TopImagex {
    height: 300px;
    width: 200px;
    object-fit: contain;
    overflow: visible;
    /* max-width: 85%; */
}

.TextInputcontainer {
    max-width: 45%;
    /* background-color: red; */
    /* height: auto; */
}

.tagLine {
    font-Size: 36px;
    color: #312951;
    font-weight: 700;
    cursor: default
}

.bottomtagline {
    font-size: 18px;
    color: black;
    font-weight: 400;
    padding-top: 10px;
    cursor: default
}

.textInput {
    width: 100%;
    height: 40px;
    border-radius: 10px;
    padding-left: 20px;
    margin-top: 20px;
}

.verficationText {
    font-size: 12px;
    margin-top: 6px;
    color: #536e83;
}

.buttonDiv {
    display: flex;
    justify-content: center;
    padding: 10px 15px;
    width: 200px;
    /* margin:auto; */
    border-radius: 10px;
    margin-top: 20px;
    background-color: #312951;
    border: 1px solid black;
    color: white;
}

.button {
    padding: 10px;
    font-weight: 400;
    background-color: #fc0067;
    width: 33%;
    border-radius: 5px;
    text-align: center;
    margin-top: 30px;
    cursor: pointer;
}

.buttonText {
    font-size: 20px;
    color: white;
    cursor: pointer;
}

.main {
    display: flex;
    margin: auto;
    width: 78%;
    align-items: center;
    justify-content: center;
    gap: 15%;
    /* border: 1px solid lightgray; */
    border: 2px solid transparent;
    border-image: linear-gradient(to right, #b1008b6e, #ffae0b8a);
    border-image-slice: 1;
    padding: 50px;
    margin-top: 50px;
    border-radius: 20px;
    margin-bottom: 50px;
    /* box-shadow: 2px 2px 10px solid #ffae0b8a; */
}

.Submain {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* background-color: red; */
}

.AnimatedText {
    padding-left: 0px;
    color: #fc0067;
}

.HiddenTextContainer {
    display: none;
}

.H2Text {
    font-size: 30px;
    font-weight: 700;
    /* color: linear-gradient(to bottom, #b1008b6e, #ffae0b8a); */
    background: linear-gradient(to right, #f32170, #ff6b08, #cf23cf);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
}

.Ptext {
    font-size: 22px;
    font-weight: 500;
    margin-top: 6px;
}

/* @media screen and (max-width: 1700px) {
    .container {
        margin: auto;
        width: 70%;
        padding-top: 100px;
        max-width: 1550px;
    }
    .TopImage {
        max-width: 85%;
    }

    .TextInputcontainer {
        text-align: left;
    }

    .bottomtagline {
        font-size: 0.8rem;
    }
} */
@media screen and (max-width: 1250px) {
    .button {
        padding: 10px;
        font-weight: 400;
        background-color: #fc0067;
        width: 50%;
        border-radius: 5px;
        text-align: center;
        margin-top: 30px;
    }

    .buttonText {
        font-size: 16px;
    }

    .TextInputcontainer {
        max-width: 100%;
        /* background-color: red; */
        /* height: 90%; */
        text-align: left;
    }

    .TopImage {
        /* height: 700px; */
        width: 450px;
        object-fit: contain;
        overflow: visible;
        color: blue;
        /* max-width: 85%; */
    }

    .container {
        padding: 0px 120px;
    }

    .tagLine {
        font-size: 25px;
    }

}


@media screen and (max-width: 1100px) {

    .bottomtagline {
        font-size: 18px;
    }

    .tagLine {
        font-size: 22px;
    }

    .button {
        width: 200px;
    }

    .Submain {
        width: 700px;
        text-align: center;
    }

    .H2Text {
        font-size: 25px;
        font-weight: 600
    }

    .Ptext {
        font-size: 18px;
        font-weight: 400;
        margin-top: 6px;
    }

    .container {
        padding: 0px 90px;
    }

    .animateThemain {
        display: flex;
        justify-content: space-between;
        align-content: center;
        margin: auto;
        width: 72%;
        margin-top: 0px;
    }

    .animateTheBottomOne {
        height: 150px;
        width: 300px;

    }

    .animateTheBottomOne22 {
        height: 150px;
        width: 300px;
        margin-left: 20px;

    }

    .animateTheBottomOne11 {
        height: 150px;
        width: 300px;
        margin-top: 100px;
        margin-left: 10px;
        margin-right: 15px;

    }

    .animateTheParaText {
        text-align: center;
        padding: 0px 5px;
        font-weight: 400;
        font-size: 16px !important;
    }

    .abc {
        /* background-color: red; */
        overflow: hidden;
        height: 70px;
        width: 120px;
        margin-top: 60px;
        margin-left: 20px;
    }

    .abc2 {
        /* background-color: red; */
        overflow: hidden;
        height: 70px;
        width: 120px;
        margin-top: 60px;
    }
}

@media screen and (max-width: 810px) {
    .Submain {
        width: 700px;
        /* background-color: red; */
        text-align: center;
    }

    .animateTheParaText {
        text-align: center;
        padding: 0px 5px;
        font-weight: 400;
        font-size: 14px !important;
    }

    .animateThemain {
        width: 83%;
    }

    .H2Text {
        font-size: 20px;
        font-weight: 600
    }

    .Ptext {
        font-size: 16px;
        font-weight: 400;
        margin-top: 6px;
    }
}

@media screen and (max-width: 1000px) {
    .container {
        flex-direction: column;
        height: auto;
        /* margin-top: 10px; */
        padding-bottom: 50px;
        /* width: 100%; */
        /* margin-bottom: 20px; */
        /* background-color: red; */
        padding: 0px;
        overflow: hidden;
        margin-bottom: 0px;

    }

    .TextInputcontainer {
        /* background-color: blue; */
        height: auto;
        text-align: center;
        margin-top: 40px;
        /* width: 800px; */
        width: 70%;
        margin-bottom: -90px;

    }

    .imageContainer {
        padding-left: 0px;
    }

    .button {
        width: 40%;
        margin: auto;
        margin-top: 30px;
    }

    .TopImage {
        height: 300px;
        width: 400px;
        margin-top: 100px;
        /* max-width: 85%; */
    }

    .main {
        margin-bottom: 0px;
    }


    /* .TopImage {
        display: none;
    } */

    .tagLine {
        font-Size: 1.8rem;
        /* text-align: center; */
        width: 90%;
    }

    .bottomtagline {
        font-Size: 1rem;
        /* text-align: center; */
    }
}

@media screen and (max-width : 700px) {
    .AnimateTopText {
        margin-bottom: 30px;
        font-size: 1.7rem;
    }

    .animateThemain {
        flex-wrap: wrap;

    }

    .abc {
        display: none;
    }

    .abc2 {
        display: none;
    }

    .animateTheBottomOne {
        margin: auto;
        margin-bottom: 30px;
        width: 80%
    }

    .animateTheBottomOne22 {
        margin-left: 0px;
        background-color: #fed5e3a4;
        margin: auto;
        /* margin-bottom: 30px; */
        width: 80%;
        margin-top: 30px;
    }

    .animateTheBottomOne11 {
        margin-left: 0px;
        margin-right: 0px;
        margin: auto;
        width: 80%;
        /* margin-top: 30px; */
    }
}

@media screen and (max-width : 630px) {
    .main {
        display: none;
    }
}

@media screen and (max-width : 453px) {
    .TextInputcontainer {
        max-width: 300px;
    }

    .TopImage {
        height: 300px;
        width: 350px;
        margin-top: 100px;
        /* max-width: 85%; */
    }

    .AnimateTopText {
        font-size: 1.5rem;
    }
}

@media screen and (max-width : 565px) {
    .tagLine {
        font-size: 1.8rem;
    }

    .button {
        width: 40%;
        /* margin: auto; */
        margin-top: 30px;
    }

    .TextInputcontainer {
        text-align: left;
    }

    .bottomtagline {
        font-size: 1rem;
        /* text-align: center; */
    }

    /* .TopImage {
        display: none;
    } */

    .container {
        margin: auto;
        width: 100%;
        /* background-color: white; */
    }

    .TextInputcontainer {
        max-width: 85%;
    }
}

@media screen and (max-width:440px) {}

@media screen and (max-width:400px) {

    .HiddenTextContainer {
        display: block;
        flex-direction: column;
    }

    .bottomtagline {
        display: none;
    }

    .tagLine {
        display: none;
    }

    .tagLineHidden {
        font-Size: 1.5rem;
        color: #312951;
        font-weight: 700;
        cursor: default
    }

    .TextInputcontainer {
        max-width: 400px;
    }

    .button {
        width: 50%;
        /* margin: auto; */
        margin-top: 30px;
    }
}