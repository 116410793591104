.Conatiner {
    margin: auto;
    width: 60%;
    /* margin-top: 80px; */
    margin-bottom: 80px;
    /* border: 1px solid black; */
}

.Image {
    width: 100%;
    height: 300px;
    object-fit: cover;
}

.BigTopText {
    font-size: 45px;
    margin-top: 30px;
}

.CreatedAt {
    margin-top: 60px;
    color: #312951;
    margin-bottom: 12px;
    /* margin-top: 20px; */
    /* padding: 0px 15px; */
    /* font-size: 25px; */
    font-weight: 400;
}

.topnameTextConatiner {
    gap: 20px;
    margin-top: 30px;
    margin-bottom: 50px;
    padding-bottom: 30px;
    border-bottom: 1px solid lightgray;
}

.Name {
    font-size: 28px;
    font-weight: 500;
}

.Sub {
    font-size: 18px;
    font-weight: 300;
    margin-top: 20px;
}

.ParaText {
    font-size: 18px;
    margin-bottom: 20px;
}

@media screen and (max-width:800px) {
    .BigTopText {
        font-size: 32px;
        margin-top: 15px;
    }
}

@media screen and (max-width:500px) {
    .Conatiner {
        width: 90%;
        margin-top: 30px;
    }

    .BigTopText {
        font-size: 26px;
    }

    .Name {
        font-size: 23px;
        font-weight: 500;
    }

    .Sub {
        font-size: 17px;
        font-weight: 400;
    }

    .ParaText {
        font-size: 15px;
        margin-bottom: 20px;
    }

    .topnameTextConatiner {
        margin-top: 30px;
        margin-bottom: 20px;
        padding-bottom: 30px;
        border-bottom: 1px solid lightgray;
    }
}