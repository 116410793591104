.Container {
    margin-bottom: 100px;
    /* margin-left: 13%; */
}

.HeadingTxtContainer {
    display: flex;
    align-items: center;
    gap: 10px;
}

.IconStarTop {
    font-size: 20px;
}

.image {
    height: 50px;
    width: 50px;
    border-radius: 100%;
}

.IconText {
    font-size: 26px;
    font-weight: 600;
}

.card {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 820px;
    /* border: 1px solid black; */
    gap: 70px;
    margin-top: 20px;
}

.InnerConatiner {
    /* background-color: blue; */
    width: 40%;
    border-top: 1px solid lightgray;
    /* padding: 10px 0px; */
}

.NameRatingConatiner {
    margin-top: 20px;
    display: flex;
    align-items: center;
    gap: 5%;
}

.Disc {
    font-size: 16px;
    padding-top: 10px;
}

@media screen and (max-width:1300px) {
    .InnerConatiner {
        /* background-color: blue; */
        /* width: 50%; */
        /* border-top: 1px solid lightgray; */
        /* padding: 10px 0px; */
    }

    .card {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
        /* border: 1px solid black; */
        gap: 70px;
        margin-top: 20px;
    }
}


@media screen and (max-width:750px) {
    .InnerConatiner {
        /* background-color: blue;
        border-top: 1px solid lightgray; */
        width: 100%;
    }

    .card {
        display: flex;
        /* flex-direction: row; */
        flex-wrap: wrap;
        max-width: 800px;
        /* border: 1px solid black; */
        /* gap: 20px; */
        margin-top: 20px;
    }
}

@media screen and (max-width:535px) {
    .Container {
        margin-top: 60px;
    }

    .IconText {
        font-size: 20px;
    }

    .card {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        max-width: 820px;
        /* border: 1px solid black; */
        gap: 30px;
        margin-top: 20px;
    }
}

@media screen and (max-width:421px) {
    .IconText {
        font-size: 18px;
    }
}