.socialmedia {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    gap: 20px;
    list-style: none;
}

.socialmedia li a {
    width: 30px;
    height: 30px;
    background-color: #fb4893db;
    display: flex;
    overflow: hidden;
    align-items: center;
    align-content: center;
    justify-content: center;
    position: relative;
    z-index: 9;
    border: 1px solid #fb489360;
}

.socialmedia li a svg {
    width: 24px;
    height: 24px;
    -o-object-fit: contain;
    object-fit: contain;
    filter: invert(100%) sepia(0%) saturate(7455%) hue-rotate(57deg) brightness(108%) contrast(105%);
}

.socialmedia li a::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-clip-path: polygon(0 0, 100% 0, 0 0, 0 100%);
    clip-path: polygon(0 0, 100% 0, 0 0, 0 100%);
    background-color: #fff;
    z-index: -1;
    top: 0;
    left: 0;
    opacity: 0;

}

.socialmedia li a:hover::after {
    animation: sideClip 0.5s linear;
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    opacity: 1;
}

.socialmedia li a:hover svg {
    animation: fadeInLeft 0.3s linear both;
    filter: invert(52%) sepia(85%) saturate(2286%) hue-rotate(54deg) brightness(92%) contrast(84%);

}

@keyframes sideClip {
    0% {
        clip-path: polygon(0 0, 100% 0, 0 0, 0 100%);
    }

    50% {
        clip-path: polygon(0 0, 100% 0, 0 100%, 0 100%);
    }

    100% {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    }
}

@keyframes fadeInLeft {
    from {
        opacity: 0;
        transform: translate3d(-100%, 0, 0);
    }

    to {
        opacity: 1;
        transform: none;
    }
}

@media screen and (max-width:600px) {
    .socialmedia li a {
        height: 25px;
        width: 25px;
    }
}