.modelWrapper {
    position: fixed;
    width: 100vw;
    z-index: 10000;
    height: 100vh;
    top: 0px;
    right: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.7);
}

.ModelContainer {
    position: relative;
    max-width: 440px;
    width: 95%;
    height: 400px;
    /* background-color: rgb(227, 224, 224); */
    background-color: white;
    /* background-color: red; */
    justify-content: space-between;
    border-radius: 5px;
    margin: auto;
    padding: 2%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.Image {
    height: 130px;
    width: 130px;
    object-fit: contain;
    user-select: none;
}

.TopText {
    font-size: 25px;
    margin-top: 30px;
    text-align: center;
    color: #fc0067;
}

.BottomText {
    margin-top: 30px;
}

.iconContainer {
    position: absolute;
    top: 10px;
    right: 10px;
    color: #fc0067;
    cursor: pointer;
}

.iconStyle {
    font-size: 24px;
    cursor: pointer;
}