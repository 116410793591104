.modelWrapper {
    position: fixed;
    width: 100vw;
    z-index: 10000;
    height: 100vh;
    top: 0px;
    right: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.7);
}

.ModelContainer {
    position: relative;
    max-width: 440px;
    width: 95%;
    background-color: rgb(227, 224, 224);
    border-radius: 5px;
    margin: auto;
    padding: 2%;
}

.iconContainer {
    position: absolute;
    top: 10px;
    right: 10px;
    color: #fc0067;
    cursor: pointer;
}

.iconStyle {
    font-size: 24px;
    cursor: pointer;
}

.imageContainer {
    display: flex;
    justify-content: center;
    /* padding-top: 20px; */
}

.Image {
    height: 60px;
}

.RegisterText {
    text-align: center;
    padding-top: 18px;
    color: #312951;
    font-size: 1.4rem;
}

.RegisterTextBottompara {
    text-align: center;
    color: black;
    font-size: 1rem;
}

.inputBoxContainer {
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.inputbox {
    padding: 13px 0px 13px 10px;
    width: 92%;
    margin: auto;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
    border-radius: 7px;
    background-color: white;
    border: none;
    cursor: pointer;
}

.buttonContainer {
    display: flex;
    justify-content: center;
    text-align: center;
    padding: 8px 0px;
    margin: 0px 60px 0px 60px;
    border-radius: 10px;
    background-color: #fc0067;
    cursor: pointer;
}

.buttonContainerText {
    width: 200px;
    margin: 0 auto;
    color: #fff;
    font-weight: 500;
    font-size: 1.1rem;
    cursor: pointer;
}

.paragraphText {
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    color: rgb(97, 97, 97);
    padding-bottom: 20px;
}

@media screen and (max-width:450px) {
    .ModelContainer {
        max-width: 440px;
        width: 80%;
        background-color: white;
        border-radius: 5px;
        margin: auto;
        padding: 2%;
        /* height: 450px; */
    }

    .Image {
        height: 60px;
    }

    .RegisterText {
        padding-top: 16px;
        font-size: 1.2rem;
    }

    .inputbox {
        padding: 8px 0px 8px 4px;
        width: 90%;
        margin: auto;
        border-radius: 8px;
        padding-left: 10px;
    }

    .buttonContainer {
        padding: 8px 0px;
        margin: 0px 60px 0px 60px;
        border-radius: 10px;
        background-color: #fc0067;
    }

    .buttonContainerText {
        /* width: 200px; */
        margin: 0 auto;
        color: #fff;
        font-weight: 400;
        font-size: 0.9rem;
    }

    .paragraphText {
        font-size: 10px;
        margin-bottom: 10px;
    }
}