.TopMost {
    margin-bottom: 80px;
}

.TextContainerTop {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.AdmissionText {
    font-weight: 500;
    font-size: 1.7rem;
    margin-bottom: 20px;
}

.ParaTextTop {
    font-size: 20px;
}

.TheMain {
    margin: auto;
    gap: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
}

.Box {
    height: 280px;
    width: 400px;
    padding: 20px;
    display: flex;
    border-radius: 10px;
    flex-direction: column;
    /* justify-content: center; */
    /* align-items: center; */
    border: 1px solid lightgray;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.4);
    margin: 60px 0px;
}

.Box1 {
    height: 280px;
    width: 400px;
    padding: 20px;
    display: flex;
    border-radius: 10px;
    flex-direction: column;
    /* justify-content: center; */
    /* align-items: center; */
    border: 1px solid lightgray;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.4);
    margin: 60px 0px;
    transform: translateY(-20px);
    background-color: #fad9e6;
}

.step {
    text-align: left;
    color: #fc0067;
    font-size: 20px;
    font-weight: 500;
}

.heading {
    text-align: left;
    margin-bottom: 20px;
    margin-top: 20px;
    font-weight: 500;
    font-size: 20px;
}

.paragraph {
    text-align: left;
    font-size: 18px;
}

.Box:hover {
    background-color: #fad9e6;
    transform: translateY(-10px);
}

.Button {
    padding: 15px 0px;
    font-size: 18px;
    margin: auto;
    width: 15%;
    border-radius: 20px;
    color: white;
    text-align: center;
    background-color: #fc0067;
}

@media screen and (max-width:1200px) {
    .Box {
        height: 240px;
        width: 280px;
        padding: 20px;
        display: flex;
        border-radius: 10px;
        margin: 30px 0px;
    }

    .Box1 {
        height: 240px;
        width: 280px;
        padding: 20px;
        display: flex;
        border-radius: 10px;
        margin: 30px 0px;
    }

    .step {
        text-align: left;
        color: #fc0067;
        font-size: 18px;
        font-weight: 500;
    }

    .heading {
        text-align: left;
        margin-bottom: 15px;
        margin-top: 15px;
        font-weight: 500;
        font-size: 18px;
    }

    .paragraph {
        text-align: left;
        font-size: 16px;
    }
}

@media screen and (max-width:900px) {
    .TheMain {
        gap: 20px;
        margin-top: 30px;
        flex-wrap: wrap;
        width: 90%;
    }

    .Box1 {
        margin: 30px 0px;
        transform: translateY(0px);
        background-color: white;
    }

    .Box {
        margin: 0px 0px;
    }

    .Button {
        padding: 12px 0px;
        font-size: 16px;
        width: 30%;
        border-radius: 20px;
        margin-top: 50px;
    }
}

@media screen and (max-width:645px) {
    .TheMain {
        gap: 20px;
        margin-top: 30px;
        flex-wrap: wrap;
        width: 90%;
    }

    .Box1 {
        margin: 30px 0px;
        transform: translateY(0px);
        background-color: white;
        width: 90%;
        align-items: center;
        height: 200px;
    }

    .Box {
        margin: 0px 0px;
        width: 90%;
        align-items: center;
        height: 200px;
        margin-bottom: 20px;
    }

    .step {
        text-align: center;
        color: #fc0067;
        font-size: 16px;
        font-weight: 500;
    }

    .heading {
        text-align: center;
        margin-bottom: 15px;
        margin-top: 15px;
        font-weight: 500;
        font-size: 16px;
    }

    .paragraph {
        text-align: center;
        font-size: 16px;
    }

    .Button {
        padding: 12px 0px;
        font-size: 16px;
        width: 40%;
        border-radius: 15px;
        margin-top: 50px;
    }

    .AdmissionText {
        font-weight: 500;
        font-size: 1.5rem;
        margin-bottom: 20px;
    }

    .ParaTextTop {
        font-size: 18px;
        width: 80%;
        text-align: center;
    }
}