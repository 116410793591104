.container {
    margin: 0 auto;
    padding: 0rem 5rem;
    margin-bottom: 90px;
    max-width: 1270px;
    width: 95%;
}

.FrequentlyTopText {
    font-size: 1.7rem;
    /* font-family: poppins; */
    cursor: default
}

.accordion .accordion_item {
    border-bottom: 1px solid #e5e5e5;
    cursor: pointer;
}

.accordion .accordion_item button[aria-expanded='true'] {
    border-bottom: 1px solid #fc0067;
    cursor: pointer;
}

.accordion button {
    position: relative;
    display: block;
    text-align: left;
    width: 100%;
    padding: 1em 0;
    color: black;
    font-size: 1.15rem;
    font-weight: 400;
    border: none;
    background: none;
    outline: none;
}

.accordion button:hover,
.accordion button:focus {
    cursor: pointer;
    color: #fc0067;
}

.accordion button:hover::after,
.accordion button:focus::after {
    cursor: pointer;
    color: #fc0067;
    border: 1px solid #fc0067;
}

.accordion button {
    padding: 0.9em 1em 1em 0;
}

.accordion_title {
    padding: 0.9em 1em 1em 0;
    /* font-family: poppins; */
    font-size: 17px;
    cursor: pointer;
}

.accordion button .icon {
    display: inline-block;
    position: absolute;
    top: 18px;
    right: 0;
    width: 22px;
    height: 22px;
    border: 1px solid;
    border-radius: 22px;
}

.accordion button .icon::before {
    display: block;
    position: absolute;
    content: '';
    top: 9px;
    left: 5px;
    width: 10px;
    height: 2px;
    background: currentColor;
}

.accordion button .icon::after {
    display: block;
    position: absolute;
    content: '';
    top: 5px;
    left: 9px;
    width: 2px;
    height: 10px;
    background: currentColor;
}

.accordion button[aria-expanded='true'] {
    color: #fc0067;
}

.accordion button[aria-expanded='true'] .icon::after {
    width: 0;
}

.accordion button[aria-expanded='true']+.accordion_content {
    opacity: 1;
    max-height: 9em;
    transition: all 200ms linear;
    cursor: pointer;
    will-change: opacity, max-height;
}

.paratextInner {
    font-size: 0.9rem;
    /* font-family: poppins; */
}


.accordion .accordion_content {
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    transition: opacity 200ms linear, max-height 200ms linear;
    will-change: opacity, max-height;
    cursor: pointer;
}

.accordion .accordion_content p {
    font-size: 15px;
    font-weight: 400;
    margin: 2em 0;
    cursor: pointer;
}

@media screen and (max-width: 552px) {
    .accordion button .icon {
        display: none;
    }

    .FrequentlyTopText {
        font-size: 1.4rem;
    }

    .accordion_title {
        padding: 0.9em o.7em 0.9em 0;
        font-size: 1rem;
    }

    .container {
        padding: 20px;
    }
}

@media screen and (max-width: 615px) {
    .container {
        padding: 40px;
    }
}