.Container {
    margin: auto;
    width: 76%;
    /* margin-top: 80px; */
}


.TopContainer {
    width: 100%;
}

.TopImage {
    width: 100%;
    height: 400px;
    object-fit: cover;
    margin-top: 40px;
}

.MainCardConatiner {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 25px;
    /* border: 1px solid black; */
    margin-top: 50px;
    margin-bottom: 80px;
    padding-bottom: 50px;
}

.card {
    height: auto;
    max-width: 350px;
    /* border: 1px solid lightgray; */
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
    /* padding: 20px; */
    text-decoration: none;
}

.image {
    height: 200px;
    width: 100%;
    object-fit: cover;
}

.BlogText {
    margin-top: 8px;
    margin-bottom: 12px;
    padding: 0px 15px;
    font-size: 25px;
    font-weight: 600;
    color: black;
}

.CreatedAt {
    margin-top: 6px;
    color: #312951;
    margin-bottom: 12px;
    padding: 0px 15px;
    /* font-size: 25px; */
    font-weight: 400;
}


.NameText {
    margin-top: 4px;
    margin-bottom: 8px;
    padding: 0px 15px;
    font-weight: 500;
}

.ParaText {
    padding: 0px 15px;
    font-size: 15px;
    padding-bottom: 20px;
    color: rgb(87, 86, 86);
}