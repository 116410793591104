* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  overflow-x: hidden;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important;
  cursor: default
}

body {
  max-width: 100vw;
}