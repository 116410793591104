.modelWrapper {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0px;
    right: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 50;
    background-color: rgba(0, 0, 0, 0.5);
}

.ModelContainer {
    position: relative;
    max-width: 440px;
    width: 95%;
    background-color: #fff;
    border-radius: 5px;
    margin: auto;
    padding: 2%;

}

.iconContainer {
    position: absolute;
    top: 10px;
    right: 10px;
    color: #fc0067;
}

.iconStyle {
    font-size: 24px;
}

.imageContainer {
    display: flex;
    justify-content: center;
    padding-top: 20px;
}

.Image {
    height: 50px;
}

.RegisterText {
    text-align: center;
    padding-top: 18px;
    color: #312951;
    font-size: 1.2rem;
}

.inputBoxContainer {
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.inputbox {
    padding: 10px 0px 10px 5px;
    width: 92%;
    margin: auto;
    border-radius: 10px;
    background-color: aliceblue;
}

.buttonContainer {
    display: flex;
    justify-content: center;
    text-align: center;
    padding: 8px 0px;
    margin: 0px 60px 0px 60px;
    border-radius: 10px;
    background-color: #fc0067;
}

.buttonContainerText {
    font-size: 1rem;
    font-weight: 400;
    color: #fff;
}

.paragraphText {
    text-align: center;
    font-size: 12px;
    color: #312951;
}

@media screen and (max-width:490px) {
    .ModelContainer {
        max-width: 350px;
        width: 85%;
        margin: auto;
    }

    .RegisterText {
        font-size: 1rem;
    }

    .buttonContainer {
        padding: 4px 0px;
        margin: 0px 80px 0px 80px;
        border-radius: 7px;
    }

    .buttonContainerText {
        font-size: 0.8rem;
    }

    .inputbox {
        padding: 8px 0px 8px 5px;
        width: 85%;
        margin: auto;
        border-radius: 10px;
        background-color: aliceblue;
    }
}